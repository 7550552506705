<template>
  <div id="home-page">
    <div class="top">
      <div class="banner">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item>
            <img src="@/assets/images/home-page-a.png" v-if="!banner" />
            <img v-lazy="banner.picPosition" v-if="banner" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="introduce">
        <div class="title">
          <h2>店铺介绍</h2>
          <p class="number">
            客服电话：<span>{{ ShopForDetails.storeServicePhone }}</span>
          </p>
        </div>
        <div class="content">
          <p>
            {{ ShopForDetails.storeIntroduce }}
          </p>
        </div>
      </div>
    </div>
    <div class="activity" v-for="(activity, index) in Activity" :key="index">
      <div class="left" @click="goActivity(activity.id, activity.locationId)">
        <img v-lazy="activity.storePic[0]" />
      </div>
      <div class="right">
        <goods
          v-for="(item, i) in activity.list"
          :key="i"
          :information="item"
        />
      </div>
    </div>
    <goods-list :goodsInfo="ShopForDetails" />
    <!-- <recommended :goods="ShopForDetails.storeHomePageCodeItems" :flag="1" /> -->
    <recommended :goods="recommendedGoods" />
  </div>
</template>

<script>
import GoodsList from "@/components/GoodsList";
import Goods from "@/components/Goods";
import Recommended from "@/components/Recommended";

export default {
  name: "home-page",

  components: {
    Goods,
    GoodsList,
    Recommended,
  },

  data() {
    return {
      ShopForDetails: "",
      banner: "", //店铺轮播图
      Activity: "", //店铺活动列表
      recommendedGoods: "",
    };
  },
  created() {
    this.getStore();
    this.getStoreActivity();
    this.getStoreBanner();
  },
  methods: {
    async getStore() {
      const data = await this.$API.store.getStoreHomePage({
        object: {
          storeId: this.$route.params.id,
          order: "",
          sortColumn: "",
        },
        pageNum: 1,
        pageSize: 25,
      });
      this.ShopForDetails = data.data.data;
      setTimeout(() => {
        this.recommendedGoods = JSON.parse(
          sessionStorage.getItem("storeInfo")
        ).storeHomePageItems.map((item) => {
          return {
            ...item,
            filePath: item.filePath.split(",")[0],
          };
        });
      }, 200);
    },
    // 店铺banner
    async getStoreBanner() {
      const data = await this.$API.store.getStoreBanner({
        object: {
          storeId: this.$route.params.id,
          enterpriseId: this.$route.query.enterpriseId,
          location: "9",
        },
      });
      this.banner = data.data.data;
    },
    // 店铺活动
    async getStoreActivity() {
      const data = await this.$API.activity.getStoreActivity({
        object: {
          id: "",
          storeId: this.$route.params.id,
        },
        pageNum: 0,
        pageSize: 4,
      });
      this.Activity = data.data.data.map((item) => {
        return {
          ...item,
          platformPic: item.platformPic.split(","),
          storePic: item.storePic.split(","),
          list: item.list.map((g) => {
            console.log(g);
            return {
              ...g,
              filePath: g.filePath.split(",")[0],
            };
          }),
        };
      });
    },
    goActivity(id, flag) {
      console.log(flag);
      if (flag === "1") {
        this.$router.push({ name: "activities-column", params: { id: id } });
      } else {
        this.$router.push({
          name: "store-activity",
          params: { activityId: id, id: this.$route.params.id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 1200px;
  height: 300px;
  display: flex;
  background: #fff;
  margin: 20px 0 30px 0;
  .banner {
    ::v-deep.el-carousel__indicator.is-active button {
      background: #fff;
      opacity: 1;
    }
    ::v-deep.el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #000000;
      opacity: 0.4;
    }
  }
  .banner,
  .introduce {
    width: 600px;
    height: 300px;
  }

  .introduce {
    padding: 16px;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    h2 {
      height: 24px;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #333333;
    }
    .number {
      height: 22px;
      font-size: 16px;
      line-height: 24px;
      color: #333333;

      span {
        color: #ec382f;
      }
    }

    .content {
      font-size: 14px;
      line-height: 24px;
      color: #666666;
      height: 228px;
      overflow: auto;
      overflow-x: hidden;

      p {
        width: 550px;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
        margin-bottom: 14px;
        // height: 228px;
      }
    }
  }
}
.activity {
  display: flex;
  margin-bottom: 40px;
  .left {
    width: 240px;
    height: 304px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    display: flex;
    width: 960px;
    height: 304px;
    background: #fff;
    .goods-box {
      border-right: 1px solid #f2f2f2;
    }
    .goods-box:nth-child(4) {
      border-right: none;
    }
  }
}
.recommended {
  margin-bottom: 30px;
  ::v-deep .content {
    border: 1px solid rgb(238, 238, 238);
    border-top: none;
  }
}

.el-carousel__item img {
  width: 100%;
  height: 100%;
}
</style>
<style>
#goods-list .goods {
  background: #fff;
}
</style>